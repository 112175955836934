<template>
  <div>
    <b-navbar>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/forum' }">
          论坛
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item tag="router-link" :to="{ path: '/AllGallery' }">
          画廊
        </b-navbar-item>
        <b-navbar-dropdown label="Info">
          <b-navbar-item href="#"> About </b-navbar-item>
          <b-navbar-item href="#"> Contact </b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
    <section style="padding-top: 20px; padding: 50px">
      <b-field label="Title" label-position="on-border">
        <b-input value="" v-model="title"></b-input>
      </b-field>
      <b-field label-position="on-border" label="贴标签">
        <b-taginput
          v-model="tags"
          :data="filteredTags"
          autocomplete
          :allow-new="true"
          :open-on-focus="true"
          field="name"
          icon="label"
          type="is-warning"
          placeholder="Add a tag"
        >
        </b-taginput>
      </b-field>
      <mavon-editor
        ref="md"
        v-model="text"
        @imgAdd="$imgAdd"
        :boxShadow="false"
        style="z-index: 0; min-height: 500px"
        placeholder="请输入正文"
      />
      <br />
      <div class="buttons">
        <b-button type="is-primary" @click="goReply" expanded>发帖</b-button>
      </div>
    </section>
  </div>
</template>

<script>
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/css/index.css"; // 未导入该样式会出现工具栏无法正常显示的问题

export default {
  components: {
    mavonEditor,
  },
  data() {
    return {
      tags: [],
      text: "",
      title: "",
      media: [],
      filteredTags: ["test", "RBQ"],
    };
  },
  methods: {
    // getFilteredTags(text) {
    //             this.filteredTags = data.filter((option) => {
    //                 return option.name
    //                     .toString()
    //                     .toLowerCase()
    //                     .indexOf(text.toLowerCase()) >= 0
    //             })
    //         },
    async $imgAdd(pos, $file) {
      let _bucket = "posts";
      let res = await this.$http.post(
        "/api/oss/presign/add",
        { bucket: _bucket },
        {
          headers: {
            pass: "",
            Authorization: "Bearer " + (await localStorage.getItem("token")),
          },
        }
      );
      console.log(res.data);
      let url;
      if (res.data.status == 20000 && res.data.url != null) {
        let status = await this.$http({
          url: res.data.url,
          data: $file,
          method: "PUT",
        });
        console.log(status);
        // url = res.data.url.split("?")[0];
        url = res.data.exp_url;
        console.log(url);
      } else {
      }
      if (url != null) {
        this.media.push({ url: url, name: $file._name });
        await this.$refs.md.$img2Url(pos, url);
      }
    },
    async goReply() {
      if (this.text == "" || this.title == "") {
        return this.$buefy.toast.open("什么都没写就想发帖");
      }
      let res = await this.$http.post(
        "/api/posts/add",
        {
          text: this.text,
          title: this.title,
          media: this.media,
          tags: this.tags,
        },
        {
          headers: {
            pass: "",
            Authorization: "Bearer " + (await localStorage.getItem("token")),
          },
        }
      );
      console.log(res.data);
      if (res.data.status == 20000) {
        this.writeReply = false;
        this.$buefy.toast.open({
          message: "成功发布",
          type: "is-success",
        });
        this.$router.push({
          name: "Post",
          query: {
            pid: res.data.pid,
          },
        });
      }
    },
  },
};
</script>

<style>
</style>